<template>
  <div>
    <el-dialog :title="isAdd ? '新增':'修改'" :visible.sync="dialogFormVisible" :append-to-body="true" :close-on-click-modal="false" @closed="cancel" width="460px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="134px" v-if="form" :disabled="disabled">
        <el-form-item label="单价低于(含等于)" prop="discount">
          <percent-input v-model="form.discount" style="width: 100%;" :base="10000" />
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="form.roleId" filterable style="width: 100%;" placeholder="请选择">
            <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { add, edit } from "@/api/priceCheckSet";
import { getAll } from "@/api/role";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      disabled: false,
      roles: "",
      form: {
        // picId: this.$uuid(),
        discount: null,
        roleId: null
      },
      rules: {
        discount: [{ required: true, message: "金额降幅为必填项" }],
        roleId: [{ required: true, message: "角色为必填项" }]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    // 确认保存
    doAdd() {
      add(this.form)
        .then(res => {
          this.dialogFormVisible = false;
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.dialogFormVisible = false;
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    getRoles() {
      getAll()
        .then(res => {
          this.roles = res;
        })
        .catch(err => {
          console.log(err.response.data.message);
        });
    },
    restForm(form) {
      this.dialogFormVisible = true;
      this.getRoles();
      this.disabled = false;
      this.form = form || {
        discount: null,
        roleId: null
      };
    }
  }
};
</script>